import {createContext, useContext, useEffect, useState} from "react"

/**
 * @type {React.Context<{isMaintenance: boolean}>} Context to store if the website is currently in maintenance
 */
const MaintenanceModeContext = createContext({
    isMaintenance: new Date().getTime() < new Date('2022-01-01T00:00:00+01:00').getTime(),
})

/**
 * @returns {{isMaintenance: boolean}} Hook to access context data
 */
function useMaintenance() {
    return useContext(MaintenanceModeContext)
}

/**
 * Provider to manage the management state
 * @param children Children components
 * @returns {JSX.Element} Rendered components
 * @constructor
 */
function MaintenanceModeProvider({children}) {
    const launchDate = new Date('2022-01-01T00:00:00+01:00').getTime()
    const today = new Date().getTime()
    const [isMaintenance, setMaintenance] = useState(today < launchDate)

    useEffect(() => {
        setMaintenance(new Date().getTime() < launchDate)
    }, [launchDate])

    const provider = {
        isMaintenance,
    }

    return (
        <MaintenanceModeContext.Provider value={provider}>
            {children}
        </MaintenanceModeContext.Provider>
    )
}

export default MaintenanceModeProvider
export {useMaintenance}