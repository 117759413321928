import dynamic from "next/dynamic"
import {useEffect, useState} from "react"
import UIkit from "uikit"
import Icons from "uikit/dist/js/uikit-icons.min"
import {MatomoProvider, createInstance} from '@jonkoops/matomo-tracker-react'
const LanguageProvider = dynamic(() => import("../services/providers/Language"))
import MaintenanceModeProvider, {useMaintenance} from "../services/providers/MaintenanceMode"
const CookieProvider = dynamic(() => import("../services/providers/CookieProvider"))
const AuthProvider = dynamic(() => import("../services/providers/Auth"))

import '../styles/index.min.css'

UIkit.use(Icons)

function MyApp({Component, pageProps}) {
    const {isMaintenance} = useMaintenance()
    const matomoInstance = createInstance({
        urlBase: 'https://stats.clownsdanslasciure.com',
        siteId: 2,
        trackerUrl: 'https://stats.clownsdanslasciure.com/matomo.php',
        srcUrl: 'https://stats.clownsdanslasciure.com/matomo.js'
    })
    const [showing, setShowing] = useState(false)

    useEffect(() => {
        setShowing(true)
    }, [])

    if (!showing) {
        return null
    }

    if (typeof window === 'undefined') {
        return <></>
    }
    else {
        return (
            <LanguageProvider>
                <CookieProvider>
                    <MatomoProvider value={matomoInstance}>
                        <MaintenanceModeProvider>
                            {
                                isMaintenance ?
                                    <AuthProvider>
                                        <Component {...pageProps} />
                                    </AuthProvider>
                                    :
                                    <Component {...pageProps} />
                            }
                        </MaintenanceModeProvider>
                    </MatomoProvider>
                </CookieProvider>
            </LanguageProvider>
        )
    }
}

export default MyApp
